import React from 'react';
import { EditorialGrid, GlobalHOC, NullCheckHoc } from '@yas/platform';

import './editorial-grid-component.scss';
import './lang/editorial-grid-component-ar.scss';
import './yas-id-registration-step-two.scss';

const FWADEditorialGrid = ({ fields }) => <EditorialGrid {...fields} />;

export default NullCheckHoc(GlobalHOC(FWADEditorialGrid));
